import React, { SyntheticEvent, useState } from "react";

const Acknowledge = () => {
  const [acknowledged, setAcknowledged] = useState<boolean>(false);

  const acknowledge = (event: SyntheticEvent) => {
    event.preventDefault();
    setAcknowledged(true);
  };
  return !acknowledged ? (
    <div id="acknowledge-container">
      <div id="acknowledge-dialog">
        <div id="acknowledge-text">
          Before clicking button below, ensure you are logged into 'Amazon Connect'
          outside Citrix
        </div>
        <button onClick={acknowledge} id="acknowledge-button">
          I am logged into Amazon Connect (CCP) outside Citrix
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Acknowledge;
